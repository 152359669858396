import React from "react"

const PigIcon = () => (
  <svg width="25px" height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <g fill="#002394">
      <path d="M20.6,26.49a7.35,7.35,0,1,0-7.36-7.33,7.34,7.34,0,0,0,7.36,7.33ZM18,22.05c.07-.24.13-.48.2-.72s.16-.31.42-.18a4.11,4.11,0,0,0,1.39.41,1.71,1.71,0,0,0,.91-.12.71.71,0,0,0,.17-1.24,2.23,2.23,0,0,0-.52-.3,12.26,12.26,0,0,1-1.42-.63A2,2,0,0,1,18,17.35a2.1,2.1,0,0,1,1.53-2c.38-.14.38-.13.38-.53v-.4c0-.3.06-.35.35-.36h.27c.63,0,.63,0,.63.63,0,.44,0,.45.45.52a4,4,0,0,1,1,.29.29.29,0,0,1,.19.38c-.08.27-.15.55-.24.82s-.16.29-.41.18a3.12,3.12,0,0,0-1.56-.31,1.08,1.08,0,0,0-.41.09.59.59,0,0,0-.14,1,2.54,2.54,0,0,0,.68.37,12.48,12.48,0,0,1,1.24.56A2.21,2.21,0,0,1,22.69,22a2.37,2.37,0,0,1-1.23.79.34.34,0,0,0-.3.39c0,.22,0,.43,0,.65a.27.27,0,0,1-.29.3h-.7a.28.28,0,0,1-.3-.32c0-.16,0-.32,0-.47,0-.35,0-.36-.35-.42a4.25,4.25,0,0,1-1.25-.36c-.3-.14-.33-.22-.24-.54Zm0,0"/>
      <path d="M37.07,27.06h-.86a9.6,9.6,0,0,0-3.16-4.45.83.83,0,0,0,0-.17,3.45,3.45,0,0,1,.51-2.79.7.7,0,0,0-.68-1.06A5.16,5.16,0,0,0,30,19.78a2.75,2.75,0,0,0-.86,1.42,8.84,8.84,0,0,1-14,5,8.59,8.59,0,0,0-.54,5.64.1.1,0,0,0,0,0,8.75,8.75,0,0,0,.74,1.94,10.16,10.16,0,0,0,1.87,2.82,5.38,5.38,0,0,1,1.46,3.45v.32h0a.64.64,0,0,0,.63.59h3.84a.63.63,0,0,0,.63-.63v-.95a13.24,13.24,0,0,0,1.71.11,12.23,12.23,0,0,0,1.34-.08v.91a.63.63,0,0,0,.63.63h3.85a.63.63,0,0,0,.63-.63v-.95a3.79,3.79,0,0,1,1.3-2.65l.13-.11,0,0a9.51,9.51,0,0,0,2.8-4.3h.81a1.17,1.17,0,0,0,1.16-1.17V28.22a1.19,1.19,0,0,0-1.18-1.16Zm-4.81.22a1,1,0,1,1,1-1,1,1,0,0,1-1,1Zm0,0"/>
    </g>
  </svg>
);

export default PigIcon;
