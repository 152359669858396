import React, { useState } from "react"
import styled from "@emotion/styled"
import Disclaimer from "../disclaimer/disclaimer"
import Download from "../download"
import Total from "../total"
import Icon from "../icon"
import icArrow from "../../../static/ic_arrow.svg"
import "./budget.scss"
import PigIcon from "../icons/piggy"

const BudgetTagText = styled.span`
  margin: 0 auto 2px;
  font-size: 1.25rem;
  font-weight: 800;
`

const TotalWrapper = styled.div`
  position: relative;
  flex: 0 0 50%;
  padding: 0.5rem 1rem;
  margin-left: 25%;
  text-align: center;
  color: white;
`

const Toggle = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  border: 1px solid #bff10b;
  border-top: 0;
  color: transparent;
  background-color: transparent;
`

const Flex = styled.div`
  display: flex;
`

const Arrow = styled(Icon)`
  position: absolute;
  right: 0;
  bottom: 0;

  margin: 0.3em;
  border: 0;
  transform: ${({ active }) => active ? 'rotate(270deg)' : 'rotate(90deg)'};

  font-size: 10px;
  line-height: 0;
  
  &.ic-rot-90:after {
    transform: rotate(90deg);
  }

  &.ic-rot-270:after {
    transform: rotate(270deg);
  }
`

export default function Budget({ budget }) {
  const [active, setActive] = useState(false);

  const expand = () => {
    setActive(v => !v)
  }

  return (
    <div className="budget">
      <div className="form-tag">
        <div className="icon">
          <PigIcon />
        </div>
        <BudgetTagText>
          Presupuesto total:
        </BudgetTagText>
      </div>
      <Flex>
        <TotalWrapper>
          <Toggle onClick={expand}>
            Toggle disclaimer
          </Toggle>
          <Total total={budget} />
          { active ? <Disclaimer /> : null}
          <Arrow icon={icArrow} active={active} />
        </TotalWrapper>
        <div>
          <Download />
        </div>
      </Flex>
    </div>
  )
}
