import React from "react"

const UserIcon = () => (
  <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <g fill="#fff">
      <path d="M24.84,24.54a6,6,0,0,0,6-6,6,6,0,0,0-6-6,6,6,0,0,0-6,6,6,6,0,0,0,6,6Z"/>
      <path d="M35.34,31.7a14.81,14.81,0,0,0-.2-1.57,12.4,12.4,0,0,0-.39-1.58,7.81,7.81,0,0,0-.65-1.48,5.57,5.57,0,0,0-1-1.28,4.32,4.32,0,0,0-1.41-.89,4.87,4.87,0,0,0-1.8-.33,1.83,1.83,0,0,0-1,.41l-1,.65a5.82,5.82,0,0,1-1.31.58,5.1,5.1,0,0,1-3.21,0,5.8,5.8,0,0,1-1.31-.58l-1-.66a1.82,1.82,0,0,0-1-.41,4.86,4.86,0,0,0-1.8.33,4.32,4.32,0,0,0-1.41.89,5.57,5.57,0,0,0-1,1.28,7.83,7.83,0,0,0-.65,1.48,12.43,12.43,0,0,0-.39,1.58,14.76,14.76,0,0,0-.2,1.57c0,.48,0,1,0,1.47a4.13,4.13,0,0,0,1.23,3.13A4.42,4.42,0,0,0,19,37.45H31a4.42,4.42,0,0,0,3.16-1.15,4.13,4.13,0,0,0,1.23-3.13C35.39,32.67,35.37,32.17,35.34,31.7Z"/>
    </g>
  </svg>
);

export default UserIcon;
