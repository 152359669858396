import React from "react"
import styled from "@emotion/styled"

const Text = styled.span`
  font-size: 0.65rem;
  font-weight: 700;
  font-style: italic;
`

const IconWrapper = styled.span`
  display: flex;
  width: 1.7rem;
  height: 1.7rem;
  margin: auto;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background-color: #00a5e1;

  font-size: 2rem;
`

const Button = styled.button`
  flex: 0 0 25%;
  border: 0; color: white;
  padding: 0.75rem 0.5rem;
  background-color: transparent;

  &:focus {
    outline: 0;
  }
`

export default function Download() {
  function download() {
    makeImage(document.body)
      .then(async(uri) => {
        const img = await renderImage(uri);
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext('2d').drawImage(img, 0, 0);
        const link = document.createElement('a');
        link.setAttribute('download', '');
        link.href = canvas.toDataURL('image/png');
        link.click();
      })
  }

  return (
    <Button onClick={download}>
      <IconWrapper>
        <svg width="1.7rem" height="1.7rem" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
          <path fill="#fff" d="M32.4 26L25 33.5l-7.4-7.3 1.4-1.4 5 5v-17h2v17l5-5zm4.9 9.3H12.8v2h24.4zm0 0"/>
        </svg>
      </IconWrapper>
      <Text>Exportar</Text>
    </Button>
  )
}


function resolveAsset(url) {
  return new Promise(function(resolve) {
    const request = new XMLHttpRequest();
    request.addEventListener('readystatechange', () => {
      if (request.readyState !== 4)
        return;
      if (request.status !== 200) {
        resolve('R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==');
        return;
      }
      const encoder = new FileReader();
      encoder.onloadend = () => resolve(encoder.result.split(/,/)[1]);
      encoder.readAsDataURL(request.response);
    });
    request.responseType = 'blob';
    request.timeout = 30000;
    request.open('GET', url, true);
    request.send();
  });
}

const uid = (() => {
  let index = 0;
  return () => 'u' + (('0000' + (Math.random() * Math.pow(36, 4) << 0).toString(36)).slice(-4)) + index++;
})();

function loadImage(image, src) {
  return new Promise((resolve, reject) => {
    image.onload = resolve;
    image.onerror = reject;
    image.src = src;
  })
}

function getMimeType(url) {
  const match = /\.([^.\/]*?)$/g.exec(url);
  const extension = match ? (match[1].toLowerCase()) : '';
  return ({
    'woff': 'application/font-woff',
    'woff2': 'application/font-woff',
    'ttf': 'application/font-truetype',
    'eot': 'application/vnd.ms-fontobject',
    'png': 'image/png',
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'gif': 'image/gif',
    'tiff': 'image/tiff',
    'svg': 'image/svg+xml'
  })[extension] || '';
}

/**
 * @param uri {string}
 * @returns {Promise<HTMLImageElement>}
 */
function renderImage(uri) {
  return new Promise((resolve, reject)  => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = () => reject();
    image.src = uri;
  });
}

async function _cloneNode(node, filter, root) {
  if (!root && filter && !filter(node))
    return;
  const clone = (node instanceof HTMLCanvasElement)
    ? await renderImage(node.toDataURL())
    : node.cloneNode(false);
  const children = Array.from(node.childNodes);
  return Promise.resolve()
    .then(() => (
      (children.length === 0)
        ? clone
        : children.reduce((done, child) => {
          return done
            .then(() => _cloneNode(child, filter))
            .then((childClone) => {
              if (childClone)
                clone.appendChild(childClone);
            });
        }, Promise.resolve())
    ))
    .then(() => {
      if (!(clone instanceof Element))
        return clone;
      const source = window.getComputedStyle(node);
      const target = clone.style;
      if (source.cssText)
        target.cssText = source.cssText;
      else {
        Array.from(source).forEach((name) => {
          target.setProperty(name, source.getPropertyValue(name), source.getPropertyPriority(name));
        });
      }

      [':before', ':after'].forEach((element) => {
        const style = window.getComputedStyle(node, element);
        const content = style.getPropertyValue('content');

        if (content === '' || content === 'none')
          return;

        const background = style.getPropertyValue('background-image');
        if (background && /url/.test(background)) {
          const width = style.getPropertyValue('width');
          const height = style.getPropertyValue('height');
          const url = /url\("(.*)"\)/g.exec(background)[1];
          resolveAsset(url)
            .then((data) => renderImage(dataAsUrl(data, getMimeType(url))))
            .then((image) => {
              image.style.width = width;
              image.style.height = height;
              clone.appendChild(image);
            });
          return;
        }

        const className = uid();
        clone.className = clone.className + ' ' + className;
        const styleElement = document.createElement('style');
        const selector = '.' + className + ':' + element;
        const cssText = style.cssText ? (
          `${style.cssText} content: ${style.getPropertyValue('content')};`
        ) : (
          Array.from(style)
            .map((name) => (
              `${name}: ${style.getPropertyValue(name)} ${(style.getPropertyPriority(name) ? ' !important' : '')}`
            ))
            .join('; ') + ';'
        );

        styleElement.appendChild(document.createTextNode(`${selector}{${cssText}}`));
        clone.appendChild(styleElement);
      });

      if (node instanceof HTMLTextAreaElement)
        clone.innerHTML = node.value;
      if (node instanceof HTMLInputElement)
        clone.setAttribute('value', node.value);

      if (clone instanceof SVGElement) {
        clone.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        if (!(clone instanceof SVGRectElement))
          return clone;
        ['width', 'height'].forEach((attribute) => {
          const value = clone.getAttribute(attribute);
          if (!value)
            return;
          clone.style.setProperty(attribute, value);
        });
      }

      return clone;
    });
}

function dataAsUrl(content, type) {
  return 'data:' + type + ';base64,' + content;
}

/**
 * @param string {string}
 * @param REGEX {RegExp}
 * @returns {Promise<string>}
 */
async function getUrl(string, REGEX) {
  if (!(string.search(REGEX) !== -1))
    return string;

  const result = [];
  let match;
  while ((match = REGEX.exec(string)) !== null)
    result.push(match[1]);

  return result.filter(url => !(url.search(/^(data:)/) !== -1))
    .reduce((done, url) => (
      done.then((string) => (
        resolveAsset(url)
          .then((data, _url) => {
            const escaped = url.replace(/([.*+?^${}()|\[\]\/\\])/g, '\\$1');
            const regex = new RegExp(`(url\\([\'"]?)(${escaped})([\'"]?\\))`, 'g');
            return string.replace(regex, `$1${(`data:${getMimeType(_url)};base64,${data}`)}$3`);
          })
      ))
    ), Promise.resolve(string));
}

async function inlineAllImages(node) {
  if (!(node instanceof Element))
    return node;

  const background = node.style.getPropertyValue('background');
  if (!background)
    return node;

  const REGEX = /url\(['"]?([^'"]+?)['"]?\)/g;
  const inlined = await getUrl(background, REGEX);
  node.style.setProperty(
    'background',
    inlined,
    node.style.getPropertyPriority('background')
  );
  if (node instanceof HTMLImageElement) {
    const element = node;
    if (node.src.search(/^(data:)/) !== -1)
      return;
    const data = await resolveAsset(node.src);
    return loadImage(element, dataAsUrl(data, getMimeType(node.src)));
  }
  return Promise.all(
    Array.from(node.childNodes)
      .map((child) => inlineAllImages(child))
  );
}

async function makeImage(node) {
  const URL_REGEX = /url\(['"]?([^'"]+?)['"]?\)/g;
  const shouldProcess = (string) => (string.search(URL_REGEX) !== -1);
  const clone = await _cloneNode(node, null, true);
  Array.from(clone.querySelectorAll('select'))
    .forEach((select) => {
      const index = Array.from(clone.querySelectorAll(`[name=${select.name}]`))
        .findIndex(s => s === select);
      const docSelect = document.getElementsByName(select.name)[index];
      const value = docSelect.value;
      const option = Array.from(docSelect.childNodes)
        .find(o => o.value === value);
      const text = option.innerText;
      Array.from(select.childNodes)
        .forEach((option) => {
          option.innerText = text;
        });
    });

  let cssRules = [];
  const styleSheets = Array.from(document.styleSheets);
  styleSheets.forEach((sheet) => {
    try {
      Array.from(sheet.cssRules || [])
        .forEach(cssRules.push.bind(cssRules));
    } catch (e) {
      console .log('Error while reading CSS rules from ' + sheet.href, e.toString());
    }
  });
  await Promise.all(
    cssRules.filter(rule => rule instanceof CSSImportRule)
      .map(rule => (
        fetch(rule.href)
          .then(r => r.text())
          .then((text) => {
            const iframe = document.createElement('iframe');
            document.head.appendChild(iframe);
            const style = iframe.contentDocument.createElement('style');
            style.textContent = text;
            iframe.contentDocument.head.appendChild(style);
            Array.from(iframe.contentDocument.styleSheets[0].cssRules || [])
              .forEach(cssRules.push.bind(cssRules))
          })
      ))
  )
  let rules = cssRules
    .filter(rule => rule.type === CSSRule.FONT_FACE_RULE)
    .filter(rule => shouldProcess(rule.style.getPropertyValue('src')));
  const webFonts = rules.map((webFontRule) => {
    return {
      resolve: () => {
        const URL_REGEX = /url\(['"]?([^'"]+?)['"]?\)/g;
        return inlineAll(webFontRule.cssText, (webFontRule.parentStyleSheet || {}).href);

        async function inlineAll(string, baseUrl) {
          if (!(string.search(URL_REGEX) !== -1))
            return string;
          const result = [];
          let match;
          while ((match = URL_REGEX.exec(string)) !== null)
            result.push(match[1]);
          return result.filter(url => !(url.search(/^(data:)/) !== -1))
            .reduce((done, url) => (
              done.then((string) => inline(string, url, baseUrl))
            ), Promise.resolve(string));
        }

        async function inline(string, url, baseUrl) {
          const _url = baseUrl ? resolveUrl(url, baseUrl) : url;
          const data = await resolveAsset(_url);
          const dataUrl = dataAsUrl(data, getMimeType(url));
          return string.replace(urlAsRegex(url), '$1' + dataUrl + '$3');

          function resolveUrl(url, baseUrl) {
            const doc = document.implementation.createHTMLDocument();
            const base = doc.createElement('base');
            doc.head.appendChild(base);
            const a = doc.createElement('a');
            doc.body.appendChild(a);
            base.href = baseUrl;
            a.href = url;
            return a.href;
          }

          function urlAsRegex(url) {
            const escaped = url.replace(/([.*+?^${}()|\[\]\/\\])/g, '\\$1');
            return new RegExp('(url\\([\'"]?)(' + escaped + ')([\'"]?\\))', 'g');
          }
        }
      },
      src: () => webFontRule.style.getPropertyValue('src')
    };
  });
  const cssStrings = await Promise.all(
    webFonts.map(webFont => webFont.resolve())
  );
  console.log(cssStrings)
  const cssText = cssStrings.join('\n');
  const styleNode = document.createElement('style');
  clone.appendChild(styleNode);
  styleNode.appendChild(document.createTextNode(cssText));
  await inlineAllImages(clone);
  clone.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml');
  const xhtml = new XMLSerializer().serializeToString(clone)
    .replace(/#/g, '%23')
    .replace(/\n/g, '%0A');
  const foreignObject = `<foreignObject x="0" y="0" width="100%" height="100%">${xhtml}</foreignObject>`;
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="${node.scrollWidth}" height="${node.scrollHeight}">${foreignObject}</svg>`;
  return 'data:image/svg+xml;charset=utf-8,' + svg
}
