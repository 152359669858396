import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import FormButton from "./form-button.style"

const PlaceRow = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
`

const PlaceFlex3 = styled.label`
  flex: 3;
  margin-right: 0.75rem;
`

const PlaceFlex1 = styled.label`
  flex: 1;
  margin-right: 0.75rem;
`

const Select = (props) => (
  <select className="form-control" {...props} />
)

const Input = (props) => (
  <input className="form-control" type="text" name="days" inputMode="numeric" required {...props}/>
)

const PlaceForm = ({ onChange, deletable, countries, index }) => {
  const [value, setValue] = useState({
    country: 0,
    days: 1
  })

  const updateValue = ({ target }) => {
    const value = +target.value
    if (isNaN(value))
      return
    if (/country/.test(target.name))
      onChange('country', value)
    else
      onChange(target.name, value)
    setValue((val) => ({
      ...val,
      [target.name]: value
    }))
  }

  const remove = () => {
    onChange('_delete', true);
  }

  useEffect(() => {
    if (countries.length) {
      onChange('country', 0)
      onChange('days', 1)
    }
  }, [countries.length])

  return (
    <PlaceRow>
      <PlaceFlex3>
        <Select value={value.country} name={`country`} onChange={updateValue}>
          {
            countries.map((country, index) => (
              <option key={index} value={index}>
                { country.name }
              </option>
            ))
          }
        </Select>
      </PlaceFlex3>
      <PlaceFlex1 aria-label="days">
        <Input value={value.days} onChange={updateValue} />
      </PlaceFlex1>
      {
        deletable
          ? (
            <FormButton onClick={remove}>
              Eliminar
            </FormButton>
          )
          : null
      }
    </PlaceRow>
  )
}

const PlacesForm = ({ places, onChange, countries }) => (
  places.map((place, index) => (
    <PlaceForm key={place.id}
               countries={countries}
               deletable={places.length > 1}
               index={index}
               onChange={(name, value) => onChange(place.id, name, value)} />
  ))
)

export default PlacesForm;
