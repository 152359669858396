import styled from "@emotion/styled"

const Icon = styled.i`
  &:after {
    display: flex;
    content: '';
    width: 1em;
    height: 1em;
    align-items: center;
    justify-content: center;
  
    background-size: 100%;
    background-position: 50%;
    background-image: ${({ icon }) => `url(${icon})`};
  }
`

export default Icon
