import React from "react"
import "./disclaimer.scss"

export default function Disclaimer() {
  return (
    <div className="disclaimer">
      <p>IMPORTANTE</p>
      <ul>
        <li>
          Este presupuesto es un aproximado basado en costos reales de los destinos
        </li>
        <li>
          Este presupuesto <strong>no incluye:</strong> Boletosde avión redondo, traslado entre ciudades, seguro
          de gastos médicos atracciones, actividades, entradas a museos, tours, ni gastos varios cómo lavandería,
          souvenirs.
        </li>
        <li>
          Es recomendable ahorrar un extra para cualquier emergencia o eventualidad
        </li>
      </ul>
    </div>
  )
}
