import React from "react"

const RestaurantIcon = () => (
  <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <g fill="#fff">
      <path d="M36.82,33.74,27,24,15.62,12.53h-.89l-.18.46a8.2,8.2,0,0,0-.59,3.62,5.11,5.11,0,0,0,1.43,3.26l8.92,8.92,1.18-1.18,8.25,9.22a2.19,2.19,0,1,0,3.1-3.1Z"/>
      <path d="M13.18,33.71a2.19,2.19,0,0,0,3.1,3.1l7-7-3.1-3.1Z"/>
      <path d="M29.91,23.95a3.65,3.65,0,0,0,2.36-1.06l5.17-5.17-1-1-4,4-1-1,4-4-1-1-4,4-1-1,4-4-1-1-5.17,5.17A3.65,3.65,0,0,0,26,20.08a2.11,2.11,0,0,1-.15.66l3.36,3.36A2.11,2.11,0,0,1,29.91,23.95Z"/>
    </g>
  </svg>
);

export default RestaurantIcon;
