import React from "react"
import styled from "@emotion/styled"

const TotalSpan = styled.span`
  white-space: nowrap;
  font-size: 1.65rem;
  font-weight: 900;

  &:before {
    content: '$ ';
    font-size: 0.85rem;
  }
`

const Message = styled.p`
  margin: 0;
  font-size: 0.75rem;
  font-weight: 300;
  font-style: italic;
`

export default function Total({ total }) {
  return (
    <>
      <TotalSpan>{ pricing(total) }</TotalSpan>
      <Message>Dólares americanos</Message>
    </>
  )

  function pricing(total) {
    let [dollars, cents] = String(total).split('.');
    if (!cents)
      cents = '00';
    const arrays = Array.from({
      length: Math.ceil(dollars.length / 3)
    }, () => {
      const last = dollars.slice(-3);
      dollars = dollars.slice(0, -3);
      return last;
    });
    return arrays.reverse().join(',') + '.' + (cents + '0').substring(0, 2);
  }
}
