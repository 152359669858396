import React from "react"
import styled from "@emotion/styled"

const FormButton = styled((props) => {
  return <button type="button" {...props} />
})`
  padding: 0.3125rem 0.5625rem;
  border: 0;
  color: #fff;
  background-color: #9c9b9b;
  font-size: 1rem;
  font-weight: 600;
  
  &:disabled {
    opacity: 0.6;
  }
`

export default FormButton
