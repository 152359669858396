import React from "react"

const LocationIcon = () => (
  <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <g fill="#fff">
      <path className="cls-2"
            d="M25.35,12.77a9.74,9.74,0,0,0-9.64,9.81c.06,6.66,8.85,16.35,9.22,16.76a.88.88,0,0,0,1.3,0c.37-.42,9-10.26,8.92-16.92A9.74,9.74,0,0,0,25.35,12.77Zm.13,14.62a4.89,4.89,0,1,1,4.85-4.94A4.9,4.9,0,0,1,25.48,27.39Z"/>
    </g>
  </svg>
);

export default LocationIcon;
