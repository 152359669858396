import React from 'react'
import styled from '@emotion/styled'
import logo from './logo.png'
import map from './mapa.png'

const HeaderContent = styled.div`
  padding: 10px 0 35px;
  background-image: url(${map});
  background-size: cover;
  background-position: 50%;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 20px),
    calc(50% + 75px) calc(100% - 20px),
    50% 100%,
    calc(50% - 75px) calc(100% - 20px),
    0% calc(100% - 20px)
  );
`

const H1 = styled.h1`
  padding: 0 90px;
  margin: 0;
`

const H2 = styled.h2`
  padding: 0 30px;
  margin: 0;
  color: #bff10b;
  text-shadow: 0 2px 5px #000000ad;
  font-weight: 800
`

const H3 = styled.h3`
  margin: 0;
  color: #fff;
  text-shadow: 0 2px 5px #000000ad;
  font-size: 40px;
  font-weight: 800
`

const ImageContained = styled.img`
  width: 100%;
  height: 100%;
  max-height: 100px;
  object-fit: contain;
`

const Header = styled(({ className }) => (
  <header className={className}>
    <HeaderContent>
      <H1>
        <ImageContained src={logo} alt="Mi viaje por el mundo" />
      </H1>
      <H2>Calculadora de Presupuesto de Viaje por</H2>
      <H3>Europa</H3>
    </HeaderContent>
  </header>
))`
  position: relative;
  padding-bottom: 10px;
  text-align: center;
  
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  
    content: '';
    width: 100%;
    height: 23px;
    background-image: linear-gradient(90deg, #bff10b2b, #bff10b 10%, #bff10b 90%, #bff10b2b);
    clip-path: polygon(
      0 0,
      calc(50% - 75px) 0px,
      50% 20px,
      calc(50% + 75px) 0,
      100% 0, 100% 3px,
      calc(50% + 75px) 3px,
      50% 23px,
      calc(50% - 75px) 3px,
      0% 3px
    );
  }
`

export default Header
