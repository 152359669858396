import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"

import Header from '../components/header/header'
import Form from '../components/form'
import SEO from '../components/seo'
import Budget from '../components/budget/budget'
import logo from '../../static/logo.png'
import '../styles/global.scss'

const Main = styled.main`
  max-width: 500px;
  margin: 0 auto;
`

const FooterLink = styled.a`
  text-decoration: none;
`

const WhiteLink = styled.a`
  display: inline-block;
  padding: 0.3rem 0.7rem;
  margin: 0 auto 0.5rem;
  border-radius: 100px;
  color: #002394;
  text-decoration: none;
  background-color: white;
  font-size: 0.7rem;
  font-style: italic;
`

const Footer = styled.footer`
  padding: 0.5rem 0;
  text-align: center;

  color: #002394;
  background-color: #bff10b;

  clip-path: polygon(0 100%, 0 20%, 5% 20%, 10% 0, 90% 0, 95% 20%, 100% 20%, 100% 100%);
`

const IndexPage = () => {
  const [travellers, setTravellers] = useState(1);
  const [budget, setBudget] = useState(0);
  const [days, setDays] = useState(0);
  const [places, setPlaces] = useState([{
    id: Math.random().toString(36).substr(2, 9),
    country: 0,
    days: 1
  }])

  useEffect(() => {
    setDays(places.reduce((count, { days }) => (
      count + days
    ), 0));
  }, [places]);

  const formChanged = ({ travellers, total }) => {
    setTravellers(travellers);
    setBudget(total)
  }

  return (
    <>
      <SEO title="Home" />
      <Header />
      <Main>
        <Form onChange={formChanged} places={places} setPlaces={setPlaces} />
        <div className="p-3">
          <Budget budget={budget} />
          <div className="align-center text-white">
            { isNaN(travellers) || <p>{ travellers } personas por { days } días de viaje</p> }
            <img className="mb-3" src={logo} alt="Logo" height={120} />
          </div>
        </div>
        <div className="flex">
          <WhiteLink href="https://www.miviajeporelmundo.com/">
            Para más información sobre esta calculadora has <strong>click aquí</strong>
          </WhiteLink>
        </div>
      </Main>
      <Footer>
        <FooterLink href="https://www.miviajeporelmundo.com/">
          www.<strong>miviajeporelmundo</strong>.com
        </FooterLink>
      </Footer>
    </>
  )
}

export default IndexPage
