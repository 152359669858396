import React from "react"

const CalendarIcon = () => (
  <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <g fill="#fff">
      <path d="M37.46,31.57a5.89,5.89,0,1,1-5.89-5.89A5.9,5.9,0,0,1,37.46,31.57Zm-1.95,0a3.94,3.94,0,1,0-3.94,3.94A3.95,3.95,0,0,0,35.52,31.57ZM33,30.6h-.44v-1a1,1,0,0,0-1.95,0v2a1,1,0,0,0,1,1H33a1,1,0,1,0,0-1.95Zm.58-16.12H32.3v-1a1,1,0,0,0-1.95,0v1H25.93v-1a1,1,0,0,0-1.95,0v1H19.6v-1a1,1,0,0,0-1.95,0v1H16.43a3.9,3.9,0,0,0-3.9,3.9V33.57a3.9,3.9,0,0,0,3.9,3.9h7.45a1,1,0,1,0,0-1.95H16.43a2,2,0,0,1-1.95-1.95V18.38a2,2,0,0,1,1.95-1.95h1.22v1a1,1,0,1,0,1.95,0v-1H24v1a1,1,0,0,0,1.95,0v-1h4.43v1a1,1,0,1,0,1.95,0v-1h1.27a2,2,0,0,1,1.95,1.95v5.55a1,1,0,1,0,1.95,0V18.38A3.9,3.9,0,0,0,33.57,14.48Z"/>
    </g>
  </svg>
);

export default CalendarIcon;
