import React from "react"

const BeerIcon = () => (
  <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <g fill="#fff">
      <path d="M33.9,21.63H30.84v-.5a4,4,0,0,0-1.68-7.22,4.71,4.71,0,0,0-7.76-2.17A3.74,3.74,0,0,0,17,13.9h-.24A4,4,0,0,0,14.18,21l.06,16.74A1.78,1.78,0,0,0,16,39.53l13.12-.05a1.78,1.78,0,0,0,1.76-1.78V35.1H34a3.31,3.31,0,0,0,3.29-3.32l0-6.85A3.31,3.31,0,0,0,33.9,21.63ZM28.43,19.7l-10.36,0v3.6a1.12,1.12,0,0,1-2.24,0l0-3.89a1.8,1.8,0,0,1,1.62-3.2,1.12,1.12,0,0,0,1.52-1,1.51,1.51,0,0,1,1.49-1.41,1.48,1.48,0,0,1,.73.18,1.12,1.12,0,0,0,1.45-.33A2.46,2.46,0,0,1,27.09,15a1.12,1.12,0,0,0,1.2,1.06h.13a1.8,1.8,0,1,1,0,3.61Zm5.74,12.08a.24.24,0,0,1-.24.24H30.88l0-7.32h3.06a.24.24,0,0,1,.24.24Z"/>
    </g>
  </svg>
);

export default BeerIcon;
