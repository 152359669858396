import * as React from "react"
import { useContext } from "react"

const FormGroupContext = React.createContext()

function Group ({ children }) {
  const id = Math.random().toString(36).substr(2, 9)
  return (
    <FormGroupContext.Provider value={id}>
      { children }
    </FormGroupContext.Provider>
  )
}

function Label (props) {
  const id = useContext(FormGroupContext);
  return <label htmlFor={id} {...props} />
}

function Select(props) {
  const id = useContext(FormGroupContext);
  return <select id={id} className="form-control" {...props}/>
}

Group.Label = Label
Group.Select = Select

export default Group
