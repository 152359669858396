import React from "react"

const TrainIcon = () => (
  <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <g fill="#fff">
      <path d="M25,10.42c-6.75,0-12.27.74-12.28,6.11l0,14.57a5.43,5.43,0,0,0,5.36,5.38l-2.31,2.3v.77l18.41,0v-.77l-2.3-2.31a5.43,5.43,0,0,0,5.38-5.36l0-14.57c0-5.37-5.51-6.15-12.26-6.16Zm-7,23a2.3,2.3,0,1,1,2.31-2.3,2.36,2.36,0,0,1-2.31,2.3Zm5.39-9.19-7.67,0,0-7.67,7.67,0Zm8.42,9.22a2.3,2.3,0,1,1,2.31-2.3,2.36,2.36,0,0,1-2.31,2.3Zm2.32-9.2-7.67,0,0-7.67,7.67,0Zm0,0"/>
    </g>
  </svg>
);

export default TrainIcon;
